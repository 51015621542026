import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import { injectIntl } from "gatsby-plugin-intl"
import {
  ContainerStatics,
  MainTitleStatics,
  SecondaryTitleStatics,
  TextWithMarginStatics,
  TextListElementStatics,
} from "../components/global"
import styled from "styled-components"

const Privacy = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_privacy" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_privacy" })

  return (
    <Layout {...{ location }}>
      <SEO title={SEOTitle} description={SEODescription} />
      <ContainerStaticsStyled>
        <MainTitleStatics>
          {intl.formatMessage({ id: "main_title_privacy" })}
        </MainTitleStatics>
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "first-block-title_privacy" })}
        </SecondaryTitleStatics>
        <div className="subtitle">
          {intl.formatMessage({ id: "first-block-first-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "first-block-first-text_privacy" })
          .split("\t")
          .map((item, i) => (
            <TextListElementStatics key={i}>{item}</TextListElementStatics>
          ))}
        <div className="subtitle">
          {intl.formatMessage({ id: "first-block-second-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "first-block-second-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <div className="subtitle">
          {intl.formatMessage({ id: "first-block-third-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "first-block-third-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        {intl
          .formatMessage({ id: "first-block-third-list_privacy" })
          .split("\t")
          .map((item, i) => (
            <TextListElementStatics key={i}>{item}</TextListElementStatics>
          ))}
        <div className="subtitle">
          {intl.formatMessage({ id: "first-block-fourth-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "first-block-fourth-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "second-block-title_privacy" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "second-block-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        {intl
          .formatMessage({ id: "second-block-list_privacy" })
          .split("\t")
          .map((item, i) => (
            <TextListElementStatics key={i}>{item}</TextListElementStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "third-block-title_privacy" })}
        </SecondaryTitleStatics>
        <div className="subtitle">
          {intl.formatMessage({ id: "third-block-first-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "third-block-first-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <div className="subtitle">
          {intl.formatMessage({ id: "third-block-second-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "third-block-second-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <div className="subtitle">
          {intl.formatMessage({ id: "third-block-third-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "third-block-third-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <div className="subtitle">
          {intl.formatMessage({ id: "third-block-fourth-subtitle_privacy" })}
        </div>
        {intl
          .formatMessage({ id: "third-block-fourth-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "fourth-block-title_privacy" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "fourth-block-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "fifth-block-title_privacy" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "fifth-block-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "sixth-block-title_privacy" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "sixth-block-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
        <SecondaryTitleStatics>
          {intl.formatMessage({ id: "seventh-block-title_privacy" })}
        </SecondaryTitleStatics>
        {intl
          .formatMessage({ id: "seventh-block-text_privacy" })
          .split("\n")
          .map((item, i) => (
            <TextWithMarginStatics key={i}>{item}</TextWithMarginStatics>
          ))}
      </ContainerStaticsStyled>
    </Layout>
  )
}

export default injectIntl(Privacy)

const ContainerStaticsStyled = styled(ContainerStatics)`
  margin-bottom: 100px;

  .subtitle {
    ${({ theme }) => theme.font_size.small};
    color: ${({ theme }) => theme.color.black.greydark};
    margin: 20px 0;

    @media (max-width: ${props => props.theme.screen.lg}) {
      ${({ theme }) => theme.font_size.xsmall};
    }

    @media (min-width: ${props =>
        props.theme.screen.sm}) and (max-width: ${props =>
        props.theme.screen.md}) {
      ${({ theme }) => theme.font_size.xxsmall};
    }
  }
`
